import React from 'react'

const Loader = () => {
  return (
    <>
   <div className=' d-flex  align-items-center  justify-content-center  vw-100  vh-100 '>
   <div className="loader">
  <div className="justify-content-center jimu-primary-loading"></div>
</div>
   </div>
    </>
  )
}

export default Loader