export const server = 'https://www.heinrichlimited.com/administration/'
export const server_api = 'https://www.heinrichlimited.com/administration/api/v1/'
export const imgUrl = 'public/uploads/what_we_do/medium/'
export const largeImgUrl = 'public/uploads/what_we_do/large/'
export const productImgUrl = 'public/uploads/products/large/'
export const blogimageUrl = 'public/uploads/blog/large/'
export const clientimg = 'public/uploads/gallery/small/'
export const url = "https://www.heinrichlimited.com/"
export const url2 = "https://www.heinrichlimited.com"

