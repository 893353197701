import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <>
    <div className="container d-flex align-items-center justify-content-center py-5 page-not-found">
       <div className="py-5 text-center">
       <h1>404</h1>
        <h2>Page Not Found</h2>
        <p className=' text-dark-emphasis'>We are sorry but the page you were looking for was not found!...</p>
        <Link to="/" className='search-more text-white py-2 px-4 rounded-1 text-decoration-none '>Home</Link>
       </div>
    </div>
    </>
  )
}

export default PageNotFound