import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import './style/style.css'
import Navbar from "./components/partial/Navbar";
import Footer from "./components/partial/Footer";
import React, { Suspense } from "react";
import Loader from "./components/card/Loader";
import PageChange from "./components/card/PageChange";
import PageNotFound from "./page/PageNotFound";
const Home = React.lazy(() => import('./page/Home'))
const StoriesDetails = React.lazy(() => import('./page/StoriesDetails'))
const ServiceDetails = React.lazy(() => import('./page/ServiceDetails'))
const ProductDetail = React.lazy(() => import('./page/ProductDetail'))
const Services = React.lazy(() => import('./page/Services'))
const Product = React.lazy(() => import('./page/Product'))
const Stories = React.lazy(() => import('./page/Stories'))
const Contact = React.lazy(() => import('./page/Contact'))
const SubCategory = React.lazy(() => import('./page/SubCategory'))
const Support = React.lazy(() => import("./page/Support")) ;
const Company = React.lazy(() => import("./page/Company"))  ;
const Career = React.lazy(() => import("./page/Career")) ;
const SearchProduct = React.lazy(() => import("./page/SearchProduct")) ;
const Terms = React.lazy(() => import("./page/Terms")) ;
const CareerDetails = React.lazy(() => import("./page/CareerDetails")) ;
const Sustainability = React.lazy(() => import("./page/Sustainability")) ;


function App() {
 
  return (
   <>
   <Router >
    <PageChange/>
   <Navbar/>
   <Suspense fallback={<Loader/>}>
   <Routes>
    <Route exact path="/" element={<Home/>}/>
    <Route exact path="/services" element={<Services/>}/>
    <Route exact path="/services/:slug/:id" element={<ServiceDetails/>}/>
    <Route exact path="/stories" element={<Stories/>}/>
    <Route exact path="/stories/:slug/:id" element={<StoriesDetails/>}/>
    <Route exact path="/product/:category/:id" element={<Product/>}/>
    <Route exact path="/product/:subcategory/:category/:productslug/:productid/:category_id/:subcategory_id" element={<ProductDetail/>}/>
    <Route exact path="/product/:slug/:subcid/:catid" element={<SubCategory/>}/>
    <Route exact path="/contact" element={<Contact/>}/>
    <Route exact path="/privacy" element={<Terms/>}/>
    <Route exact path="/support" element={<Support/>}/>
    <Route exact path="/company" element={<Company/>}/>
    <Route exact path="/sustainability" element={<Sustainability/>}/>
    <Route exact path="/career" element={<Career/>}/> 
    <Route exact path="/career/:jid" element={<CareerDetails/>}/>
    <Route  path='/search/:query' element={<SearchProduct/>} />
    <Route  path='*' element={<PageNotFound/>} />
   </Routes>
   </Suspense>
    <Footer/>
   </Router>
   
   </>
  );
}

export default App;
