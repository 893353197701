import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useProductCategoryQuery } from "../../redux/api/productApi";
import { useNewsLetterMutation } from "../../redux/api/bannerApi";
import toast, { Toaster } from "react-hot-toast";
const Footer = () => {
  const { data: category } = useProductCategoryQuery();
  const [input, setInput] = useState("");
  const [postData] = useNewsLetterMutation();
  const date = new Date();
  const year = date.getFullYear();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input === "") return toast.error("Please Provide Email Address");
    postData({ email: input }).then((res) => {
      if (res?.data?.status === 200) {
        setInput("");
        return toast.success("Subscribed Successfully");
      }
       else return toast.error("Something went wrong");
    });
  };

  return (
    <>
      <Toaster position="top-right" />
      <footer className=" pt-5 pb-3">
        <div className="container mt-3">
          <div className="row justify-content-center   gy-3">
            <div className="col-lg-4">
              <Link to="/">
                {/* <img src={logo} alt="Logo" width="150px" /> */}
                <h1 className=" fw-bold ">HEINRICH</h1>
              </Link>
              <p className="text-sm w-75 text-start ">
              A-62,1st Floor, DDA Sheds, Okhla Phase II, New Delhi - 110020
              </p>
              <h5>1800 419 1117</h5>
              <div>
                {" "}
                <Link className="text-sm">info@heinrichlimited.com</Link>
              </div>
              <div>
                {" "}
                <Link className="text-sm">sales@heinrichlimited.com</Link>
              </div>
              <div>
                {" "}
                <Link className="text-sm">Technical@heinrichlimited.com</Link>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row gy-3">
                <div className="col-lg-6">
                  <h6 className=" m-0 p-0">
                    {" "}
                    <strong>Download</strong>
                  </h6>
                  <ul className="p-0 m-0">
                    <li className="m-0">
                      <Link to="/company">About us</Link>
                    </li>
                    <li className="m-0">
                      <Link to="">Certificates</Link>
                    </li>
                  </ul>
                  <h6 className=" m-0 p-0 mt-2">
                    <strong>Support</strong>
                  </h6>
                  <ul className="p-0 m-0">
                    <li className="m-0">
                      <Link to="/support">Support</Link>
                    </li>
                    <li className="m-0">
                      <Link href="">Customer Service</Link>
                    </li>
                    <li className="m-0">
                      <Link href="">Technical Support</Link>
                    </li>
                  </ul>
                  <h6 className=" m-0 p-0 mt-2">
                    <strong>Career</strong>
                  </h6>
                  <ul className="p-0 m-0">
                    <li className="m-0">
                      <Link to="/support">Support</Link>
                    </li>
                    <li className="m-0">
                      <Link to="/career">Careers</Link>
                    </li>
                    <li className="m-0">
                      <Link href="">Job Search</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 mb-2">
                  <h6 className="m-0 p-0">
                    <strong>Products Category</strong>
                  </h6>
                  <ul className="p-0 m-0">
                    {category?.data?.slice(0,10).map((i) => (
                      <li className="m-0" key={i?.id}>
                        <Link  to={`/product/${i?.slug}/${i?.id}`}>
                          {i?.category_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <h6 className="mb-3">Get in touch</h6>
              <div className="mb-4">
                <Link
                  to="https://www.facebook.com/heinrichlimited?ref=aymt_homepage_panel"
                  className="mx-1"
                  target="_blank"
                >
                  {" "}
                  <i className=" border rounded-5 p-2 border-3 border-white   social-icon fa-brands fa-square-facebook"></i>
                </Link>
                <Link
                  to="https://www.instagram.com/heinrichlimited/"
                  target="_blank"
                  className="mx-1"
                >
                  <i className="  border rounded-5 p-2 border-3 border-white  social-icon fa-brands fa-instagram"></i>
                </Link>
                <Link
                  to="https://www.youtube.com/channel/UC1Ce1L3qNUEtxzRy3KsNAjQ"
                  target="_blank"
                  className="mx-1"
                >
                  <i className="  border rounded-5 p-2 border-3 border-white  social-icon fa-brands fa-youtube"></i>
                </Link>
                <Link
                  to="https://twitter.com/Heinrichlimited"
                  target="_blank"
                  className="mx-1"
                >
                  <i className="  border rounded-5 p-2 border-3 border-white  social-icon fa-brands fa-square-twitter"></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/heinrichlimited?originalSubdomain=in"
                  target="_blank"
                  className="mx-1"
                >
                  <i className="  border rounded-5 p-2 border-3 border-white   social-icon fa-brands fa-linkedin"></i>
                </Link>
              </div>
              <h6 className="mb-3">Subscribe for newsletter</h6>
              <form
                className="d-flex bg-white position-relative "
                onSubmit={handleSubmit}
              >
                <input
                  className="text border-0 py-2 ps-2"
                  placeholder="Enter Email Address"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
                <button
                  className=" bg-black text-white p-2 border-0 position-absolute  end-0 bottom-0 "
                  type="submit"
                >
                  Subscribe
                </button>
              </form>
            </div>
            <hr />
          </div>
        </div>

        <div className=" d-flex  justify-content-center  align-items-center  flex-wrap px-5">
          <p className="p-0 m-0 text-sm">
            @{year} Heinrich Limited, All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
