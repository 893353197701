import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { server_api } from '../../contant'

export const bannerApi = createApi({
    reducerPath: "bannerApi",
    baseQuery: fetchBaseQuery({baseUrl: `${server_api}`}),
    endpoints: (builder) => ({
         clients: builder.query({
            query: () => ({
             url: `client`,
             method: "GET",
            })
         }),
         contact: builder.mutation({
            query: (data) => ({
             url: `enquiry`,
             method: "POST",
             body: data
            })
         }),
         enquiry: builder.mutation({
            query: (data) => ({
             url: `productenquiry`,
             method: "POST",
             body: data
            })
         }),
         newsLetter: builder.mutation({
            query: (data) => ({
             url: `newsletter`,
             method: "POST",
             body: data
            })
         }),
         createTicket: builder.mutation({
            query: (data) => ({
             url: `ticket/create`,
             method: "POST",
             body: data
            })
         }),
         jobenquiry: builder.mutation({
            query: (data) => ({
             url: `carrer`,
             method: "POST",
             body: data
            })
         }),
         joblist: builder.query({
            query: () => ({
             url: `joblist`,
             method: "GET",
            })
         }),
         
    })
})

export const {  useClientsQuery, useContactMutation, useEnquiryMutation, useNewsLetterMutation, useJoblistQuery, useJobenquiryMutation, useCreateTicketMutation } = bannerApi

