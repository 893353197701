import { configureStore } from "@reduxjs/toolkit";
import  reducer  from "./reducer/reducer";
import { serviceApi } from "./api/serviceApi";
import { productApi } from "./api/productApi";
import { blogApi } from "./api/blogApi";
import { bannerApi } from "./api/bannerApi";


export const store = configureStore({
    reducer: {
        [reducer.name]: reducer,
        [serviceApi.reducerPath]: serviceApi.reducer,
        [productApi.reducerPath]: productApi.reducer,
        [blogApi.reducerPath]: blogApi.reducer,
        [bannerApi.reducerPath]: bannerApi.reducer
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([serviceApi.middleware],[productApi.middleware],[blogApi.middleware],[bannerApi.middleware])
})