import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { server_api } from '../../contant'

export const blogApi = createApi({
    reducerPath: "blogApi",
    baseQuery: fetchBaseQuery({baseUrl: `${server_api}`}),
    endpoints: (builder) => ({
        allBlog: builder.query({
            query: () => ({
             url: "blog",
             method: "GET",
            })
         }),
         blogDetails: builder.query({
            query: (id) => ({
             url: `blog-description/${id}`,
             method: "GET",
            })
         }),
         blogTag: builder.query({
            query: () => ({
             url: `tags`,
             method: "GET",
            })
         }),
         
    })
})

export const { useAllBlogQuery, useBlogDetailsQuery, useBlogTagQuery } = blogApi

