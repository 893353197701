import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { server_api } from '../../contant'

export const serviceApi = createApi({
    reducerPath: "serviceApi",
    baseQuery: fetchBaseQuery({baseUrl: `${server_api}`}),
    endpoints: (builder) => ({
        allService: builder.query({
            query: (page) => ({
             url: `whatwedo`,
             method: "GET",
            })
         }),
         serviceDetails: builder.query({
            query: (id) => ({
             url: `whatwedo/detail?id=${id}`,
             method: "GET",
            })
         }),
    })
})

export const { useAllServiceQuery, useServiceDetailsQuery } = serviceApi