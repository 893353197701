import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { server_api } from '../../contant'

export const productApi = createApi({
    reducerPath: "productApi",
    baseQuery: fetchBaseQuery({baseUrl: `${server_api}`}),
    endpoints: (builder) => ({
        allProduct: builder.query({
            query: (id) => ({
             url: `allcatproduct/${id}`,
             method: "GET",
            })
         }),
        productCategory: builder.query({
            query: () => ({
             url: "category",
             method: "GET",
            })
         }),
         productDetails: builder.query({
            query: ({subcategory, category, productslug, productid, category_id, subcategory_id}) => ({
             url: `product-description/${subcategory}/${category}/${productslug}/${productid}/${category_id}/${subcategory_id}`,
             method: "GET",
            })
         }),
    })
})

export const { useAllProductQuery, useProductDetailsQuery, useProductCategoryQuery } = productApi

