import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../asset/logo.png";
import { useProductCategoryQuery } from "../../redux/api/productApi";
import axios from "axios";
import { server_api } from "../../contant";
const Navbar = () => {
  const [classname, setClassName] = useState("");
  const [activeclass, setActiveClass] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [input, setInput] = useState("");
  const [product, setProduct] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [showSubMenu, setshowSubMenu] = useState(false);
  const [subMenuState, setSubMenuState] = useState(false);
  const navigate = useNavigate();
  const toggle = () => {
    if (classname === "") return setClassName("show");
    else return setClassName("");
  };

  const { data: category } = useProductCategoryQuery();

  const handleChange = (e) => {
    setInput(e.target.value);
    if (e.target.value.trim() !== "") {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleSearch = () => {
    if (input !== "") {
      axios.get(`${server_api}seachproduct?keyword=${input}`).then((res) => {
        setProduct(res?.data?.data);
      });
    }
  };

  const handleClick = (i) => {
    navigate(
      `/product/${i?.subcategory?.slug}/${i?.category?.slug}/${i?.slug}/${i?.id}/${i?.category?.id}/${i?.subcategory?.id}`
    );
    setClassName("");
    setInput("");
    setShowDropdown(false);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      handleSearch();
    }, 500);
    clearTimeout(() => {
      time();
    });
  }, [input]);

  const handleClose = () => {
    setShowDropdown(false);
    setShowInput(false);
  };

  const toggleSubMenu = () => {
    if (subMenuState === false) return setSubMenuState(true);
    else return setSubMenuState(false);
  };

  const handleClassChange = () => {
    setClassName("");
    setActiveClass("");
  };

  const handleShowMenu = () => {
    if (showSubMenu === false) {
      setshowSubMenu(true);
      setActiveClass("");
    } else {
      setshowSubMenu(false);
    }
  };

  const handleproduct = (categoryItem) => {
    navigate(`/product/${categoryItem?.slug}/${categoryItem?.id}`);
    setActiveClass("active");
    setshowSubMenu(false);
  };

  const toggleInput = () => {
    if (input !== "") {
      navigate(`/search/${input}`);
      setShowDropdown(false);
      setClassName("");
    }
    if (showInput === false) {
      setInput("");
      return setShowInput(true);
    } else {
      setInput("");
      return setShowInput(false);
    }
  };

  useEffect(() => {
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          layout:
            window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
          autoDisplay: false,
        },
        "google_translate_element"
      );
    };
  }, []);
  return (
    <>
      <nav className="bg-white z-3  ">
        <div className="container z-3  d-flex justify-content-between align-items-center py-1 py-lg-3 py-xl-3 position-relative">
          <div className="logo ps-2">
            <Link to="/" onClick={() => setActiveClass("")}>
              <img src={logo} alt="Logo" width="150px" />
            </Link>
          </div>
          <ul
            className={`p-lg-0 mb-0  d-flex justify-content-end   align-items-center m-lg-0 nav-list w-100 ${classname}`}
          >
            <>
              <li
                className="ms-4 my-3 my-lg-0 d-none d-lg-block    d-xl-block "
                onClick={handleClassChange}
              >
                <NavLink to="/services" className="nav-item  fw-medium">
                  What We Do
                </NavLink>
              </li>
              <li
                className="ms-4 my-3 my-lg-0 position-relative d-none d-lg-block   d-xl-block "
                onClick={handleShowMenu}
                onMouseLeave={() => setHoveredCategory(null)}
              >
                <Link to="#" className={`nav-item fw-medium ${activeclass}`}>
                  Products <i className="fa-solid fa-angle-down"></i>
                </Link>
              </li>
              <li
                className="ms-4 my-3 my-lg-0 d-none d-lg-block   d-xl-block "
                onClick={handleClassChange}
              >
                <NavLink to="/stories" className="nav-item fw-medium">
                  Stories
                </NavLink>
              </li>
              <li
                className="ms-4 my-3 my-lg-0 d-none d-lg-block    d-xl-block "
                onClick={handleClassChange}
              >
                <NavLink
                  to="/contact"
                  className="nav-item fw-medium "
                  onClick={handleClassChange}
                >
                  Contact
                </NavLink>
              </li>
              <li className="search-form position-relative d-flex d-none d-lg-flex d-xl-flex border  border-2 border-black-50 rounded-1 ps-2 ms-4">
                <input
                  type="text"
                  className="border-0  w-100 "
                  value={input}
                  onChange={handleChange}
                  placeholder="Search Product..."
                />
                {showDropdown && (
                  <ul
                    className="position-absolute top-100  end-0 shadow ps-0 pt-3 z-3 bg-white w-100 mt-1 overflow-auto "
                    onBlur={handleClose}
                    style={{height: "300px"}}
                  >
                    {product?.map((i) => (
                      <>
                        <li
                          key={i?.id}
                          className=" ps-3 text-dark-emphasis "
                          onClick={() => handleClick(i)}
                        >
                          <Link key={i?.id}>{i?.name}</Link>
                        </li>
                        <hr />
                      </>
                    ))}
                  </ul>
                )}
                <span
                  className="fw-medium search-more text-white px-2 d-flex  align-items-center justify-content-center rounded-end-1 "
                  onClick={toggleInput}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </span>
              </li>

              <li className="ms-4 my-3 my-lg-0" style={{ cursor: "pointer" }}>
                <span className=" d-block " onClick={toggle}>
                  <i className="social fs-4 fa-solid fa-bars"></i>
                </span>
              </li>
            </>
          </ul>

          {showSubMenu && (
            <ul className="dropdown-item position-absolute ps-0 py-3 bg-white d-flex flex-wrap gy-2">
              {category?.data?.map((categoryItem) => (
                <>
                  <li
                    key={categoryItem?.id}
                    className=" px-3 text-dark-emphasis py-2 d-lg-flex  flex-column  justify-content-center  align-items-center fw-light "
                    onMouseEnter={() => setHoveredCategory(categoryItem)}
                    // onClick={() => setActiveClass("active")}
                    onClick={() => handleproduct(categoryItem)}
                    style={{width: '180px'}}
                  >
                    <img src={categoryItem?.icon} alt="" height={25} />
                    <Link>{categoryItem?.category_name}</Link>
                  </li>
                </>
              ))}
            </ul>
          )}
        </div>
      </nav>
      <ul className={`sidebar  mb-0 z-2 ${classname}`}>
        <>
          <li className="search-form position-relative d-flex d-lg-none d-xl-none border  border-2 border-black-50 rounded-1 ps-2 ">
            <input
              type="text"
              className="border-0  w-100 "
              value={input}
              onChange={handleChange}
              placeholder="Search Product..."
            />
            {showDropdown && (
              <ul
                className="position-absolute top-100  end-0 shadow ps-0 pt-3 z-3 bg-white w-100 mt-1 overflow-auto "
                onBlur={handleClose}
                style={{height: "300px"}}
              >
                {product?.map((i) => (
                  <React.Fragment key={i?.id}>
                    <li
                      key={i?.id}
                      className=" ps-3 text-dark-emphasis list-unstyled  "
                      onClick={() => handleClick(i)}
                    >
                      <Link>{i?.name}</Link>
                    </li>
                    <hr />
                  </React.Fragment>
                ))}
              </ul>
            )}
            <span
              className="fw-medium search-more text-white px-2 d-flex  align-items-center justify-content-center rounded-end-1 "
              onClick={toggleInput}
              style={{ cursor: "pointer" }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </span>
          </li>
          <div id="google_translate_element"></div>
          <li className="mx-3 my-3 d-block " onClick={() => setClassName("")}>
            <NavLink to="/support" className="nav-item  fw-medium">
              Support
            </NavLink>
          </li>
          <li className="mx-3 my-3 d-block " onClick={() => setClassName("")}>
            <NavLink to="/career" className="nav-item  fw-medium">
              Career
            </NavLink>
          </li>
          <li className="mx-3 my-3 d-block " onClick={() => setClassName("")}>
            <NavLink to="/company" className="nav-item  fw-medium">
              About Us
            </NavLink>
          </li>
         
          <li
            className="mx-3 my-3 d-block d-lg-none  d-xl-none   "
            onClick={() => setClassName("")}
          >
            <NavLink to="/services" className="nav-item  fw-medium">
              What We Do
            </NavLink>
          </li>

          <li
            className="mx-3 my-3 d-block d-lg-none  d-xl-none "
            onClick={() => setClassName("")}
          >
            <NavLink to="/stories" className="nav-item fw-medium">
              Stories
            </NavLink>
          </li>
          <li
            className="mx-3 my-3 d-block d-lg-none  d-xl-none "
            onClick={() => setClassName("")}
          >
            <NavLink to="/contact" className="nav-item fw-medium ">
              Contact
            </NavLink>
          </li>
          <li
            className="mx-3 my-3 position-relative dropdown-toggle d-block d-lg-none d-xl-none"
            onMouseLeave={() => setHoveredCategory(null)}
            onClick={toggleSubMenu}
          >
            <Link className="nav-item fw-medium">Products</Link>
            {subMenuState && (
              <ul className="dropdown-item position-absolute shadow ps-0 pt-3">
                {category?.data?.map((categoryItem) => (
                  <React.Fragment key={categoryItem?.id}>
                    <li
                      className="px-3 text-dark-emphasis py-2 d-flex justify-content-between"
                      onMouseEnter={() => setHoveredCategory(categoryItem)}
                      key={categoryItem?.id}
                    >
                      <Link
                        to={`/product/${categoryItem?.slug}/${categoryItem?.id}`}
                        onClick={() => setClassName("")}
                      >
                        {categoryItem?.category_name}
                      </Link>
                    </li>
                    <hr />
                  </React.Fragment>
                ))}
              </ul>
            )}
          </li>
        </>
      </ul>
    </>
  );
};

export default Navbar;
