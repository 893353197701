import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const PageChange = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [location.pathname]);


  return (
    <div></div>
  )
}

export default PageChange